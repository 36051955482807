/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCzwvgktpR9CxEAv4SkcaB3tYueItoOaoc",
  "appId": "1:14926725444:web:b8ad2cbdcb95c5a69c1711",
  "authDomain": "schooldog-instance-demo.firebaseapp.com",
  "measurementId": "G-2D4VJRSS8F",
  "messagingSenderId": "14926725444",
  "project": "schooldog-instance-demo",
  "projectId": "schooldog-instance-demo",
  "showGaBranding": true,
  "storageBucket": "schooldog-instance-demo.appspot.com"
}
